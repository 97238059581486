export default {
  "languages": {
    "english": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["英语"])},
    "italian": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["意大利语"])},
    "chinese": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["中文"])}
  },
  "global": {
    "barriers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["障碍"])},
    "comingsoon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["即将推出"])},
    "optional": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["可选"])},
    "driveoff": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["开车离开"])},
    "nmop": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["无支付手段"])},
    "parking": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["停车"])},
    "loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["加载"])},
    "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["输入..."])},
    "blacklist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["黑名单"])},
    "unblacklist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["取消黑名单"])},
    "blacklisted_at": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["列入黑名单的时间"])},
    "make": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["制造商"])},
    "model": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["型号"])},
    "fuelType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["燃料类型"])},
    "colour": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["颜色"])},
    "remove": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["移除"])},
    "select_a_file": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["选择文件"])},
    "reason": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["原因"])},
    "staging": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["暂存"])},
    "save_changes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["保存更改"])},
    "clear_filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["清除过滤"])},
    "upload": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["上传"])},
    "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["取消"])},
    "back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["返回"])},
    "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["保存"])},
    "action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["操作"])},
    "important_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["重要信息！"])},
    "yes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["是"])},
    "whitelist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["白名单"])},
    "browse": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["浏览"])},
    "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["删除"])},
    "no_data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["没有数据显示"])},
    "close": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["关闭"])},
    "vehicle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["车辆"])},
    "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["添加"])},
    "mode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["模式"])},
    "copy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["复制"])},
    "generate": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["生成 ", _interpolate(_named("text"))])},
    "show": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["显示 ", _interpolate(_named("text"))])},
    "hide": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["隐藏 ", _interpolate(_named("text"))])},
    "client": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["客户 ", _interpolate(_named("text"))])},
    "site": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["站点 ", _interpolate(_named("text"))])},
    "all_available": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["全部可用"])},
    "category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["类别"])},
    "claim": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["索赔 ", _interpolate(_named("text"))])},
    "select": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["选择 ", _interpolate(_named("text"))])},
    "enter": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["输入 ", _interpolate(_named("text"))])},
    "global": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["全球 ", _interpolate(_named("text"))])},
    "select_client": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["选择客户"])},
    "escalated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["升级"])},
    "active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["活跃"])},
    "vars_technology": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["VARS Technology"])},
    "warning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["警告"])},
    "clear": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["清除"])}
  },
  "navigation": {
    "top": {
      "applications": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["应用"])},
      "settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["设置"])},
      "logout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["登出"])}
    },
    "side": {
      "register_device": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["注册设备"])},
      "face": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["面部"])},
      "faces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["面部"])},
      "incidents": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["事件"])},
      "alerts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["警报"])},
      "tools": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["工具"])},
      "face_blacklist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["人员"])},
      "dashboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["仪表板"])},
      "process": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["处理索赔"])},
      "new_claim": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["新索赔"])},
      "view_claims": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["查看索赔"])},
      "events": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["事件"])},
      "blacklist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["黑名单"])},
      "view_blacklist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["查看黑名单"])},
      "national_blacklist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["全国黑名单"])},
      "add_new_blacklist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["添加新车辆"])},
      "sites": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["站点"])},
      "claims": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["索赔"])},
      "customer_service": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["客户服务"])},
      "clients": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["客户"])},
      "view_clients": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["查看客户"])},
      "new_client": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["新客户"])},
      "installers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["安装人员"])},
      "vehicles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["车辆"])},
      "brands": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["品牌"])},
      "accounting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["会计"])},
      "view_sites": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["查看站点"])},
      "new_site": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["新站点"])},
      "map": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["地图"])},
      "groups": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["群组"])},
      "new_group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["新群组"])},
      "users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["用户"])},
      "view_users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["查看用户"])},
      "new_user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["新用户"])},
      "roles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["角色"])},
      "permissions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["权限"])},
      "permission_groups": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["权限组"])},
      "logs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["日志"])},
      "modules": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["模块"])},
      "view_modules": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["查看模块"])},
      "new_module": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["新模块"])},
      "system": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["系统"])},
      "monitoring": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["监控"])},
      "whitelists": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["白名单"])},
      "client_whitelist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["客户白名单"])},
      "global_whitelist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["全球白名单"])},
      "reports": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["报告"])},
      "documentation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["文档"])},
      "help_centre": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["帮助中心"])},
      "staging": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["预发布"])},
      "manage_claims": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["管理索赔"])},
      "video_guides": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["视频指南"])},
      "sales": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["销售"])},
      "statistics": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["统计"])},
      "vehicle_search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["车辆搜索"])},
      "navigation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["导航"])},
      "favourite": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["收藏"])},
      "no_fav": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["无收藏"])}
    }
  },
  "login": {
    "auth_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["请登录您的账户并继续进入仪表盘。"])},
    "email_address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["电子邮箱地址"])},
    "username": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["用户名"])},
    "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["密码"])},
    "sign_in": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["登录"])},
    "errors": {
      "username": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["用户名是必填项！"])},
      "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["密码是必填项！"])},
      "auth_failed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["输入的电子邮箱地址或密码不正确。请再试一次。"])}
    }
  },
  "dashboard": {
    "select_site": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["选择站点"])},
    "todays_data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["今日数据"])},
    "todays-payments": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("text")), " 付款"])},
    "todays-losses": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("text")), " 损失"])},
    "yesterday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["昨天"])},
    "worst_site": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("text")), " 最差站点"])},
    "lost_today": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["今日损失"])},
    "worst_pump": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["最差泵"])},
    "fuel_recovery": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["回收燃料"])},
    "this_year": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["今年"])},
    "overview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["概览"])},
    "active_parking_sites": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["活跃的停车站点"])},
    "being_recovered": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["正在回收"])},
    "chart_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["以下统计数据反映了当前正在处理的索赔。点击每个字段查看详细信息。"])},
    "money_recovered": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["已回收金额"])},
    "recovered_on_site": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["站点回收"])},
    "losses_reported": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["报告的损失"])},
    "recovery": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["资金回收"])},
    "money_lost": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["未偿还金额"])},
    "last": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["上一次 ", _interpolate(_named("text"))])},
    "this": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["本次 ", _interpolate(_named("text")), " 至今"])},
    "previous_year_data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["查看上一年数据"])},
    "outstanding": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["未完成"])},
    "today": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["今天"])},
    "from": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["从此时间 ", _interpolate(_named("text"))])},
    "blacklist_today": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["今天的事件"])},
    "blacklist_week": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["本周的事件"])},
    "blacklist_month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["本月的事件"])},
    "blacklist_year": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["今年的事件"])},
    "blacklist_total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["列入黑名单的车辆"])},
    "graphs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["图表"])},
    "parking_revenue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["停车收入"])},
    "potential_blacklist_savings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["黑名单潜在节省"])},
    "claims_reported": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["报告的索赔"])},
    "claims_value": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["索赔价值"])},
    "claims_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["索赔数量"])},
    "blacklisted_vehicle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["列入黑名单的车辆"])},
    "cancellations_per_month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["每月取消"])},
    "error_msg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["加载仪表盘数据失败"])},
    "currently_processing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["正在处理"])},
    "last_year": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["去年"])},
    "plp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["黑名单警报预防潜在损失"])},
    "recovery_of_fuel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["燃料回收"])},
    "fuel_lost": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["未回收燃料"])},
    "weeks_data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["本周数据"])},
    "months_data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["本月数据"])},
    "years_data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["本年数据"])},
    "last_years_data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["去年的数据"])},
    "last_twelve_months": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["过去12个月"])},
    "cancellations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["取消"])},
    "last_week": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["上周"])},
    "last_month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["上个月"])},
    "fuel_recovery_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["加载燃料回收数据失败"])},
    "parking": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["停车收入"])},
    "blacklist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["黑名单活动"])},
    "plp_desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["下方的数字估算了通过列入黑名单节省的收入。"])}
  },
  "pagination": {
    "showing": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["显示 ", _interpolate(_named("showing")), " 到 ", _interpolate(_named("to")), " 共 ", _interpolate(_named("total")), " 条记录"])},
    "previous": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["上一页"])},
    "next": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["下一页"])}
  },
  "claims": {
    "processing": {
      "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["搜索"])},
      "vehicle-registration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["车辆登记号"])},
      "reference-number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["参考编号"])},
      "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["状态"])},
      "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["全部"])},
      "driveoff": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["开车离开"])},
      "nmop-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["无支付手段"])},
      "cnaf-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["顾客无过错"])},
      "parking": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["停车"])},
      "actionable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["可操作"])},
      "waiting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["等待"])},
      "nonactionable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["不可操作"])},
      "hide-locked": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["隐藏已锁定"])},
      "received-on": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["收到于"])},
      "by": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["由 ", _interpolate(_named("site"))])},
      "progress-message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["进度消息"])},
      "notes": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["无备注"]), _normalize(["1 条备注"]), _normalize([_interpolate(_named("count")), " 条备注"])])},
      "appeals": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["无申诉"]), _normalize(["1 次申诉"]), _normalize([_interpolate(_named("count")), " 次申诉"])])},
      "payments": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["无付款"]), _normalize(["1 次付款"]), _normalize([_interpolate(_named("count")), " 次付款"])])},
      "has-payments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["是否有付款？"])},
      "has-notes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["是否有备注？"])},
      "outstanding_losses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["未处理损失"])},
      "vars_invoice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["VARS 发票"])},
      "nmop": {
        "add_second_fuel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["添加第二种燃料"])},
        "edit_fuel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["编辑燃料信息"])},
        "edit_second_fuel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["编辑第二种燃料信息"])},
        "reason": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["变更原因"])},
        "fuel_change_success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["成功更新索赔详情"])},
        "fuel_change_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["更新燃料详情失败"])}
      },
      "payment": {
        "make_payment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["进行付款"])},
        "payment_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["加载付款信息失败"])},
        "vendor": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("text")), " 供应商"])},
        "amount": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("text")), " 金额"])},
        "received": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("text")), " 已收到"])},
        "notes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["备注"])},
        "make_payment_success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["成功创建付款"])},
        "make_payment_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["创建付款失败，请再试一次"])}
      }
    },
    "new": {
      "crime_reference": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["警察/犯罪参考编号"])},
      "create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["创建索赔"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["请选择您想要创建的索赔类型。"])},
      "requirements": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["以下是创建 ", _interpolate(_named("text")), " 索赔所需的不同类型信息"])},
      "nmop": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["无支付手段"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["客户已经加注了燃料，但无法在现场付款。"])},
        "agreement": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["VARS Technology - 无支付手段协议"])},
          "warning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["这是一个具有法律约束力的协议。仅当您理解其条款并希望受其约束时才接受。"])},
          "line_one": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["我同意在今天的日期起四（4）天内支付上述金额。付款应在 varsanpr.co.uk 上进行（需支付 <span class='tx-bold'>£5.00</span> 管理费）。"])},
          "line_two": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["如果未付款，我理解我将负责支付上述金额以及强制执行该协议的所有费用。"])},
          "line_three": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["我声明，我在此表格中提供的所有信息都是我所知范围内真实的。"])},
          "line_four": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["该协议规定，如果在今天的日期起四（4）天内未支付全款，<span class='tx-bold'>£5.00</span> 的管理费将增加到 <span class='tx-bold'>£15</span>，并延长10天。如果在此期间未收到全额付款，则将视为燃料盗窃，并视为开车逃逸事件，您将承担 <span class='tx-bold'>£60</span> 的费用，并可能通过债务催收机构和/或法律程序追讨。"])},
          "line_five": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["该协议规定在某些情况下使用我的个人数据，包括根据《数据保护法》使用敏感的个人数据。"])},
          "line_six": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["我在此声明，我在此或任何其他加油站没有未支付的 '无支付手段' 事件，如果发现此声明不实，我填写和签署此表格的行为可能被视为欺诈、犯罪和可被起诉。"])},
          "line_seven": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["选择以确认客户已理解并同意受上面显示的协议的约束"])}
        }
      }
    },
    "driveoff": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["逃逸"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["顾客已加油并且在没有尝试支付或拒绝支付的情况下开车离开。"])}
    },
    "abandoned": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["遗弃车辆"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["一辆车长时间被遗弃在您的站点。"])}
    },
    "dreamoff": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["无法收款"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["员工未能在现场回收款项。"])}
    },
    "nameAndAddress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["顾客姓名和地址"])},
    "vehicleRegistration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["车辆登记号"])},
    "fuelValue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["燃油金额（英镑）"])},
    "litres_of_fuel_dispensed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["加油量（升）"])},
    "pumpNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["油泵编号"])},
    "tillReceipt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["收银机小票副本"])},
    "vehicleImage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["车辆图片"])},
    "cctv": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["监控录像"])},
    "vehicleDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["车辆描述"])},
    "vehicleDuration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["遗弃天数"])},
    "createNow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["立即创建"])},
    "site": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["站点"])},
    "select_a_site": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["选择站点"])},
    "site_feedback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["请选择一个站点"])},
    "registration_feedback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["如果您不知道车辆登记号，我们将无法为您追回燃油费用。"])},
    "vehicle_make": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["车辆品牌"])},
    "vehicle_model": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["车辆型号"])},
    "vehicle_colour": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["车辆颜色"])},
    "find_make_model_color": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["查找车辆品牌、型号和颜色"])},
    "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["日期"])},
    "time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["时间"])},
    "type_of_fuel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["燃油类型"])},
    "fuel_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["燃油类型"])},
    "event_on_site": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["站点事件"])},
    "value": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["金额"])},
    "litres": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["升数"])},
    "pump": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["油泵"])},
    "select_a_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["选择类型"])},
    "petrol": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["汽油"])},
    "diesel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["柴油"])},
    "reddiesel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["红柴油"])},
    "adblue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AdBlue"])},
    "add_new_fuel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["添加新燃油"])},
    "liquid_petroleum_gas": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["液化石油气 (LPG)"])},
    "fuel_type_feedback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["请提供有效的燃油类型。"])},
    "fuel_value_feedback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["请提供有效的燃油金额。"])},
    "fuel_litres_feedback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["请提供有效的加油量。"])},
    "fuel_pump_feedback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["请提供有效的油泵编号。"])},
    "fuel_type_minimum_feedback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["您必须提供至少一种燃油类型。"])},
    "very_important": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["非常重要！"])},
    "name_and_address_alert": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["如果您不能提供司机的全名和地址，我们将无法将其处理为无支付手段的案例，并且我们的管理团队将其转为全额逃逸案件。"])},
    "customer_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["顾客姓名"])},
    "customer_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["顾客邮箱"])},
    "customer_mobile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["顾客手机"])},
    "customer_postcode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["顾客邮政编码"])},
    "locate_address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["定位地址"])},
    "select_address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["选择地址"])},
    "select_an_address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["选择一个地址"])},
    "address_one": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["地址1"])},
    "address_two": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["地址2"])},
    "address_three": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["地址3"])},
    "address_four": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["地址4"])},
    "important_notice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["重要通知"])},
    "upload_as_much_evidence": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["请确保上传尽可能多的证据以支持索赔。提供更多证据将增加我们追回燃油的机会，并减少我们未来联系您获取更多信息的需求。"])},
    "columns_marked": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["带有 <span class='text-danger'>*</span> 标记的列为必填项"])},
    "evidence_minimum": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["您必须提供至少一张车辆图片和一张收银小票。"])},
    "file": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["文件"])},
    "evidence_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["证据类型"])},
    "comments_on_evidence": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["关于证据的评论"])},
    "drive_off_report": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["逃逸报告"])},
    "other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["其他"])},
    "valid_evidence_feedback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["请选择有效的证据类型。"])},
    "comments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["评论"])},
    "please_provide_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["请提供无支付手段的描述。请尽可能详细地描述，包括："])},
    "please_provide_description_driveoff": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["请提供逃逸的描述。请尽可能详细地描述，包括："])},
    "filling_cannister": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["顾客是否在加注油桶？"])},
    "attempt_to_pay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["顾客是否尝试支付？"])},
    "enter_store": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["顾客是否进入商店？"])},
    "asked_for_fuel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["顾客是否被询问是否加了油？"])},
    "please_provide_information": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["请尽可能提供有关事件的详细信息"])},
    "create_button": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["创建 ", _interpolate(_named("text"))])},
    "error_with_data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["您提供的信息存在问题。请查看上方的错误信息以获取更多信息。"])},
    "submitting_claim": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["提交索赔"])},
    "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["索赔成功创建"])},
    "claim_created": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["参考编号为"])},
    "min_3_chars": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["至少需要3个字符！"])},
    "max_12_chars": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["最多12个字符！"])},
    "must_be_valid_time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["时间格式必须有效："])},
    "creating_claim": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["正在创建新索赔..."])},
    "uploading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["上传中"])},
    "evidence_files": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["证据文件"])},
    "file_format_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["您上传了无效的文件类型。请提供 PNG、JPEG 或 PDF 格式的文件。"])},
    "file_size_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["我们只能接受小于25MB的图片！"])},
    "total_outstanding": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["总未结金额"])},
    "remove_second_fuel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["移除第二种燃油类型"])},
    "add_new_evidence": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["添加新证据"])},
    "go_to_dashboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["前往仪表盘"])},
    "additional_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["附加信息"])},
    "clear_img": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["清除图片"])}
  },
  "evidences": {
    "created": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["已创建"])},
    "card_comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["以下列出的索赔缺少证据，我们需要这些证据才能处理。请尽快提交这些证据以避免延误或取消。"])},
    "ready_to_process": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["准备处理"])},
    "awaiting_evidences": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["等待证据"])},
    "select_a_claim_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["选择索赔类型"])},
    "update_success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["成功更新证据。"])},
    "update_failed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["更新证据失败。"])},
    "vehicle_image": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["车辆图像"])},
    "till_receipt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["收据图像"])},
    "transfer_of_liability": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["责任转移"])},
    "dvla_response": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["DVLA 响应"])},
    "dvla_enquiry": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["DVLA 查询"])},
    "notice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["通知"])},
    "other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["其他"])}
  },
  "manage_evidence": {
    "manage_claim": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["管理索赔"])},
    "claim_details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["索赔详情"])},
    "vehicle_details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["车辆详情"])},
    "missing_evidence": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["缺少证据。请上传证据并重试。"])},
    "missing_vehicle_image": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["当前缺少车辆图片！"])},
    "missing_till_receipt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["当前缺少收银小票图片！"])},
    "missing_both": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["当前缺少车辆图片和收银小票图片！"])},
    "ready_to_process": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["该索赔已准备好处理。"])},
    "upload_evidences": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["请上传至少一张清晰的车辆图片和一张清晰的收银小票图片，以便进一步处理该索赔。"])},
    "upload_evidence": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["上传证据"])},
    "uploaded_evidence": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["已上传证据"])},
    "vehicle_image_upload": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["上传车辆图片"])},
    "till_receipt_upload": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["上传收银小票"])},
    "no_image_selected_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["上传前请先选择一张图片。"])},
    "vehicle_uploaded_successfully": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["车辆图片上传成功。"])},
    "vehicle_uploaded_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["车辆图片上传失败。"])},
    "receipt_uploaded_successfully": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["收银小票上传成功。"])},
    "receipt_uploaded_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["收银小票上传失败。"])},
    "till_receipt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["收银小票"])},
    "provide_claim_cancellation_reason": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["请提供取消索赔的原因。"])},
    "cancel_claim": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["取消索赔"])},
    "valid_reason": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["请输入有效的原因。"])},
    "claim_cancelled_success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["索赔取消成功。"])},
    "failed_cancelling_claim": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["取消索赔失败。"])},
    "process_claim": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["该索赔现在可以处理。"])},
    "failed_updating_claim": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["更新索赔失败。请重试。"])},
    "status_ready_to_process": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["准备处理"])},
    "labels": {
      "fuel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["燃油"])},
      "liters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["升数"])},
      "value": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["金额"])},
      "pump": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["油泵"])},
      "user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["用户"])},
      "site": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["站点"])},
      "timestamp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["时间戳"])},
      "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["状态"])},
      "plate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["车牌"])}
    }
  },
  "events": {
    "filters": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["筛选器"])},
      "click_to_open": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["点击打开"])},
      "vehicle_registration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["车辆登记号"])},
      "site": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["站点"])},
      "select_a_site": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["选择站点"])},
      "camera": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["摄像头"])},
      "select_a_camera": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["选择摄像头"])},
      "direction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["方向"])},
      "select_a_direction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["选择方向"])},
      "leaving_site": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["离开站点"])},
      "entering_site": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["进入站点"])},
      "start_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["开始日期"])},
      "end_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["结束日期"])},
      "start_time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["开始时间"])},
      "end_time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["结束时间"])},
      "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["搜索"])},
      "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["状态"])}
    },
    "loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["加载中"])},
    "events": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["事件"])},
    "blacklist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["黑名单"])},
    "unblacklist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["移出黑名单"])},
    "view": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["查看"])},
    "press": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["按"])},
    "event_close": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["或点击图片外部以关闭此弹出窗口！"])},
    "events_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["加载事件失败。"])},
    "select_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["选择状态"])}
  },
  "event": {
    "blacklist_entries": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["黑名单条目"])},
    "company": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["公司"])},
    "reason_for_blacklist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["加入黑名单的原因"])},
    "date_blacklisted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["加入黑名单日期"])},
    "claims_outstanding": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["未结索赔"])},
    "customer_service_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["客户服务信息"])},
    "important_notice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["重要通知"])},
    "notice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["如果该车辆在黑名单上，客户服务信息将不会显示在您的VARS显示屏上。"])},
    "drive_off": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["逃逸"])},
    "no_means_of_payment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["无支付手段"])},
    "fly_tipping": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["非法倾倒垃圾"])},
    "theft": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["盗窃"])},
    "aggressive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["攻击性行为"])},
    "plate_mismatch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["车牌不符"])},
    "vars_test_vehicle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["VARS测试车辆"])},
    "vehicle_information": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["车辆信息"])},
    "claim_information": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["索赔信息"])},
    "reason_for_watchlist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["加入观察名单的原因"])},
    "money_owed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["欠款金额"])},
    "n_a": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["不适用"])},
    "notes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["备注"])},
    "csm_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["如果您希望当该车辆进入站点时提醒工作人员，但不希望将其加入黑名单，请在下方填写信息。"])},
    "csm_danger": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["如果车辆已在黑名单中，该消息将不会显示在显示屏上。如果您希望该消息显示在黑名单提醒中，请使用备注功能！"])},
    "add_note": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["添加备注"])},
    "no_notes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["没有显示备注"])},
    "no_csm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["没有客户服务信息"])},
    "update": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["更新"])},
    "camera_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["加载摄像头失败"])},
    "event_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["加载事件详情失败。"])},
    "valid_note": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["请提供有效的备注。"])}
  },
  "blacklist": {
    "create_new_blacklist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["创建新黑名单"])},
    "single_blacklist_entry": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["单个黑名单条目"])},
    "multiple_blacklist_entry": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["多个黑名单条目"])},
    "add_to_blacklist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["添加到黑名单"])},
    "remove_from_blacklist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["从黑名单中移除"])},
    "select_reason": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["选择原因"])},
    "warning_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["请确保CSV文件有两列，一列为车牌，一列为车牌被列入黑名单的原因。"])},
    "csv_file": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CSV文件"])},
    "are_you_sure": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["您确定吗"])},
    "modal_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["这将把车辆从您的黑名单中移除。"])},
    "remove_it": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["是的，移除它"])},
    "success_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["成功将车辆添加到黑名单。"])},
    "error_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["将车辆添加到黑名单失败。请重试。"])},
    "preview_data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["预览数据"])},
    "blacklist_modal_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["请选择列入黑名单的原因"])},
    "unblacklist_success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["成功将车辆从黑名单中移除。"])},
    "unblacklist_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["将车辆从黑名单中移除失败。"])},
    "load_blacklist_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["加载黑名单失败。请重试。"])},
    "aggression": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["攻击性行为"])},
    "no_reason": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["您必须提供列入黑名单的原因！"])},
    "invalid_reason": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["您必须提供有效的列入黑名单的原因！"])},
    "clear_data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["清除数据"])}
  },
  "whitelist": {
    "registration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["登记"])},
    "notes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["备注"])},
    "added_by": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["添加者"])},
    "added_on": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["添加日期"])},
    "expiry": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["到期日"])},
    "action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["操作"])},
    "no_notes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["没有备注可显示"])},
    "global_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["无法加载全局白名单。"])},
    "client_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["无法加载客户端白名单。"])}
  },
  "vehicle_notes": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["车辆备注"])},
    "on": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["于"])},
    "at": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["在"])},
    "error_msg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["无法将备注保存到车辆。"])}
  },
  "reports": {
    "filter_reports": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["筛选索赔报告"])},
    "filter_reports_face": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["筛选面部报告"])},
    "claim_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["索赔类型"])},
    "week": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["本周"])},
    "month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["本月"])},
    "quarter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["本季度"])},
    "year": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["今年"])},
    "generate_report": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["生成报告"])},
    "num_claims": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["索赔数量"])},
    "num_faces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["面部数量"])},
    "total_value": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["总金额"])},
    "total_paid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["已支付总额"])},
    "total_cancelled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["取消总数"])},
    "request_cancellation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["请求取消"])},
    "select": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["选择原因"])},
    "cancel_notice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["是否发送取消通知给司机？"])},
    "submit_request": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["提交请求"])},
    "success_msg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["成功提交取消请求。"])},
    "error_msg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["提交取消请求失败。"])},
    "filter_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["如何使用？"])},
    "filter_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["使用下方提供的筛选器生成您感兴趣的索赔报告。报告将在下方显示，您可以将其下载为Excel文档。"])},
    "filter_text_face": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["使用下方提供的筛选器生成您感兴趣的面部检测报告。报告将在下方显示，您可以将其下载为Excel文档。"])},
    "chart_overview": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("text")), " 概述"])},
    "charts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["图表"])},
    "date_received": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["接收日期"])},
    "date_paid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["支付日期"])},
    "cancel_reason": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["取消原因"])},
    "sort_by": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["排序方式"])}
  },
  "sites": {
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["名称"])},
    "postcode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["邮政编码"])},
    "reference": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["参考"])},
    "cameras": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["摄像头"])},
    "create_site": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["创建站点"])},
    "address_one": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["地址行 1"])},
    "address_two": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["地址行 2"])},
    "town": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["镇或城市"])},
    "county": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["县"])},
    "tier": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["等级"])},
    "telephone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["电话号码"])},
    "finish_and_create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["完成并创建"])},
    "site_information": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["站点信息"])},
    "site_whitelist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["站点白名单"])},
    "site_updated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["成功更新站点详细信息。"])},
    "site_update_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["保存站点更改失败。请重试。"])},
    "site_load_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["加载站点失败。"])},
    "mailing_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["在考虑将用户添加到此邮件列表时，请考虑他们将收到与该站点相关的所有启用的电子邮件。"])},
    "display": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["显示 ", _interpolate(_named("text"))])},
    "rdiesel_form": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["红柴油签到"])},
    "refusal_form": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["拒签表单"])},
    "site": {
      "site_data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["站点配置"])},
      "sign_ins": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["签到"])},
      "devices": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["设备"])},
      "rdiesel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["红柴油"])},
      "refusal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["拒签"])},
      "temperature": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["温度"])},
      "invoices": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["发票"])},
      "site_overview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["站点概述"])},
      "lifetime": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["生命周期"])},
      "portal_only": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["仅门户"])},
      "tablet_only": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["仅平板"])},
      "parking_only": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["仅停车"])},
      "full_vars_system": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["完整的VARS系统"])},
      "upload_file": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["上传文件"])},
      "preview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["预览"])},
      "select_file": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["请选择文件以查看。"])},
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["名称"])},
      "evidence_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["证据类型"])},
      "no_evidence": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["无可预览图片"])},
      "site_install_pic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["站点安装图片"])},
      "file_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["文件名"])},
      "upload_image": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["上传图片"])},
      "image_upload_success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["图片上传成功"])},
      "image_upload_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["图片上传失败。请重试。"])},
      "asset_upload_success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["成功上传证据！"])},
      "asset_upload_failed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["上传证据失败。"])},
      "active_cameras": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["活跃摄像头"])},
      "active_tablets": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["活跃平板"])},
      "active_displays": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["活跃显示屏"])}
    },
    "data": {
      "documents": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["文档"])},
      "installPics": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["安装图片"])},
      "signage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["标志"])},
      "brands": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["品牌"])},
      "upload_install": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["上传安装图片"])},
      "upload_signage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["上传标志图片"])},
      "uploaded_signage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["已上传的标志图片"])},
      "uploaded_install": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["站点安装图片"])},
      "failed_to_upload_signage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["上传标志失败。请重试。"])},
      "delete_evidence": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["删除证据"])},
      "brand": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["品牌"])},
      "add_new_brand": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["添加新品牌"])},
      "add_brand": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["添加品牌"])},
      "failed_to_load_brands": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["加载品牌失败。请重试。"])},
      "failed_to_add_brand": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["将品牌添加到站点失败。请重试。"])},
      "failed_to_delete_brand": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["从站点删除品牌失败。请重试。"])},
      "select_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["全选"])},
      "pages": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["页"])},
      "show_all_pages": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["显示所有页"])},
      "add_asset_to_site": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["添加资产到站点"])},
      "add_asset_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["上传文件前请确认文件是否安全。如有疑问，请联系支持团队进行验证。"])},
      "asset_upload_success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["文件上传成功！"])},
      "asset_upload_fail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["文件上传失败。请重试。"])},
      "add_asset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["添加资产"])},
      "asset_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["资产类型"])},
      "delete_asset_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["这将删除资产且无法恢复，是否确认？"])},
      "no_document_uploaded": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["未上传文档。"])}
    },
    "sign_ins": {
      "signins": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["签到"])},
      "date_time_signedin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["签到日期/时间"])},
      "view_signature": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["查看签名"])},
      "date_time_signout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["签出日期/时间"])}
    },
    "settings": {
      "claim_settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["索赔设置"])},
      "barriers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["屏障"])},
      "mailingList": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["邮件列表"])},
      "tab_settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["标签设置"])},
      "dashboard_pin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["仪表盘密码"])},
      "claims_success_msg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["成功 "])}
    },
    "invoice": {
      "invoices": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["发票"])},
      "reference": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["发票参考"])},
      "first_line": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["第一行"])},
      "due": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["应付"])},
      "paid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["已付"])},
      "total": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["总计 ", _interpolate(_named("text"))])},
      "created_at": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["创建日期"])}
    },
    "new": {
      "create_site_success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["成功创建新站点。"])},
      "create_site_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["创建站点失败。请重试。"])},
      "load_clients_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["无法加载客户列表。"])},
      "site_ref_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["加载站点参考失败"])},
      "select_tier": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["选择等级"])}
    },
    "mailing_list": {
      "add_to_list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["添加到邮件列表"])},
      "current_users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["当前用户"])},
      "add_user_success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["成功将用户添加到邮件列表。"])},
      "add_user_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["从邮件列表中移除用户失败。"])},
      "remove_user_success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["成功从邮件列表中移除用户。"])},
      "remove_user_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["从邮件列表中移除用户失败。"])}
    },
    "devices": {
      "camera_mode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["摄像头模式"])},
      "display_update_success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["成功更新显示名称"])},
      "display_update_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["更新显示名称失败。请重试。"])},
      "assigned": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["分配的 ", _interpolate(_named("text"))])},
      "last_authenticated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["最后验证时间"])},
      "last_ping": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["最后连接时间"])},
      "version": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["版本"])},
      "alert_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["此信息大约每15秒更新一次"])},
      "install_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["安装日期"])},
      "last_maintenance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["上次维护"])},
      "serial": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["序列号"])},
      "reverse_direction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["反向"])},
      "hide_display": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["从显示中隐藏"])},
      "error": {
        "camera_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["加载摄像头详情失败。如果问题持续，请联系支持。"])},
        "models": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["加载摄像头型号失败。如果问题持续，请联系支持。"])},
        "modes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["加载摄像头模式失败。如果问题持续，请联系支持。"])}
      }
    },
    "refusal": {
      "customer_desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["客户描述"])},
      "product": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["产品"])},
      "refused_by": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["拒绝者"])},
      "reason": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["原因"])}
    },
    "temperature": {
      "fridges": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["冰箱"])},
      "fridge_temp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["冰箱温度签到"])},
      "action_taken": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["采取的措施"])},
      "beverages": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["饮料"])},
      "fridge_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["加载冰箱数据失败"])},
      "add_fridge": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["添加新冰箱"])},
      "calibration_device_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["加载校准设备数据失败"])},
      "add_calibration_device": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["添加新校准设备"])},
      "add_calibration_device_success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["成功添加新校准设备"])},
      "add_calibration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["添加新设备"])},
      "calibration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["校准"])},
      "calibration_device_temp_failed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["加载设备温度失败"])},
      "add_fridge_success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["成功添加新冰箱"])},
      "daily_food": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["每日食品"])},
      "hot_food": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["热食"])},
      "thaw_serve": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["解冻即食食品"])},
      "shelf_life": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["保质期（天）"])},
      "food": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["食品"])},
      "food_temp_failed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["加载食品温度失败"])},
      "init_qty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["初始数量"])},
      "init_qty_thaw": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["放置数量"])},
      "qty_wasted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["浪费数量"])},
      "qty_wasted_thaw": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["解冻浪费数量"])},
      "qty_remaining": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["剩余数量"])},
      "fridge_temp_failed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["加载冰箱温度失败"])},
      "add_food_item": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["添加食品项目"])},
      "new_food_item": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["添加新食品项目"])},
      "select_food_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["选择食品类型"])},
      "add_food_item_success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["成功将新食品项目添加到列表"])},
      "add_food_item_failed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["将新食品项目添加到列表失败"])},
      "initials": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["员工缩写"])}
    },
    "staging": {
      "staging_key": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["临时密钥"])},
      "key": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["密钥"])},
      "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["类型"])},
      "created_at": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["创建日期"])},
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["名称"])},
      "assign_site": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["分配站点"])},
      "assign_role": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["分配角色"])},
      "select_a_site": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["选择站点"])},
      "select_a_role": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["选择角色"])},
      "provision": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["供应"])},
      "device_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["设备名称"])}
    },
    "labels": {
      "claim_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["索赔类型"])},
      "reported_by": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["报告人"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["描述"])},
      "quantity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["数量"])},
      "amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["金额"])},
      "admin_fee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["管理费"])},
      "sub_total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["小计"])},
      "payment_made": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["已支付款项"])},
      "tab_settings": {
        "lite_only": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["仅精简版"])},
        "staff_parking": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["员工停车"])},
        "vehicle_history": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["车辆历史"])},
        "customer_parking": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["客户停车"])},
        "sign_in": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["签到"])},
        "evidence": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["证据"])}
      },
      "parking": {
        "parking_settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["停车设置"])},
        "fine_perc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["罚款百分比"])},
        "rates_perc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["费率百分比"])},
        "max_stay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["最大停留时间"])},
        "no_return": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["无返回政策"])}
      }
    }
  },
  "csm": {
    "success_msg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["成功更新客户服务消息"])},
    "error_msg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["更新客户服务消息失败。"])}
  },
  "vehicle": {
    "blacklisted": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["列入黑名单的 ", _interpolate(_named("text"))])}
  },
  "guides": {
    "video_guides": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["视频指南"])},
    "alert_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lorem Ipsum 只是印刷和排版行业的虚拟文本。自1500年代以来，Lorem Ipsum 一直是行业的标准虚拟文本，当时一位未知的印刷商拿出了一块排字版并将其打乱，制作了样本书。"])},
    "portal_only": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["仪表盘 - 仅门户"])},
    "create_claim": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["创建索赔"])}
  },
  "vehicle_search": {
    "alert_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["车辆搜索允许您搜索所有您有权限访问的客户的车辆活动。为此，您必须提供车辆的完整登记号。"])},
    "no_of": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["数量 ", _interpolate(_named("text"))])},
    "system_logs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["系统日志"])}
  },
  "stats": {
    "one": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["无支付手段升级为逃逸"])},
      "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["以下数据显示无支付手段索赔升级为逃逸的数量。"])}
    },
    "two": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["已发送第1封逃逸信件"])},
      "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["以下数据显示已发送第1封信给登记持有者的逃逸索赔数量。"])}
    },
    "three": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["已发送第2封逃逸信件"])},
      "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["以下数据显示已发送第2封信给登记持有者的逃逸索赔数量。"])}
    },
    "four": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["无支付手段提升至债务追收"])},
      "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["以下数据显示无支付手段索赔提升至债务追收的数量。"])}
    },
    "five": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["逃逸提升至债务追收"])},
      "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["以下数据显示逃逸索赔提升至债务追收的数量。"])}
    },
    "six": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["列入黑名单的车辆总数"])},
      "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["以下数据显示所有客户中列入黑名单的车辆总数。"])}
    },
    "seven": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ANPR站点"])},
      "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["以下数据显示ANPR站点的总数。"])}
    },
    "eight": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["仅平板站点"])},
      "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["以下数据显示仅平板站点的总数。"])}
    },
    "nine": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["仅门户站点"])},
      "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["以下数据显示仅门户站点的总数。"])}
    },
    "global": {
      "alert_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["全球统计概述了用户可访问的所有客户加油站的健康状况和性能。"])}
    }
  },
  "worst_sites": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["最差站点"])},
    "money_lost": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["损失金额"])},
    "recovered": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["追回金额"])},
    "alert_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["下表列出了本月所有站点中最差的站点。"])},
    "worst_pump": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["最差油泵"])}
  }
}