<template>
  <router-view />
</template>

<script>
export default {
  created: function () {
    if (window.location.host === "server.varsanpr.com") {
      window.location.href = "https://portal.varsanpr.com";
      return;
    }

    let token = this.$store.state.auth?.user?.token;
    let url = window.location.href;
    let queryParams = url.split("?");
    if (queryParams.length > 1) {
      let query = queryParams[1];
      let queryParts = query.split("&");
      for (let i = 0; i < queryParts.length; i++) {
        let queryPart = queryParts[i];
        let queryPartParts = queryPart.split("=");
        if (queryPartParts[0] === "token") {
          token = queryPartParts[1];
          break;
        }
      }
    }
    // console.log('Token: ' + token);
    if (!token) {
      console.log("No token, logging out (App.vue)");
      localStorage.removeItem("user");
      localStorage.removeItem("jwt");
      this.$store.dispatch("auth/logout");
      window.location.href =
        "https://gateway.varsanpr.com/login?redirect=" +
        window.location.origin +
        "/login";
      return;
    }
    let payload = JSON.parse(atob(token.split(".")[1]));
    let exp = payload.exp;
    let now = Date.now() / 1000;
    if (exp < now) {
      console.log("Token expired, logging out (App.vue)");
      localStorage.removeItem("user");
      localStorage.removeItem("jwt");
      this.$store.dispatch("auth/logout");
      window.location.href =
        "https://gateway.varsanpr.com/login?redirect=" +
        window.location.origin +
        "/login";
    } else {
      console.log("Token valid");

      if (this.$store.state.auth.user) {
        this.$store.dispatch("auth/checkToken", token).catch(() => {
          this.$store.dispatch("auth/logout");
          window.location.href =
            "https://gateway.varsanpr.com/login?redirect=" +
            window.location.origin +
            "/login";
        });
      }
    }
  },
  methods: {
    // historyBack(event) {
    //   if(event.keyCode === 32) {
    //     this.$router.go(-1);
    //   }
    // }
  },
};
</script>
