import { ApplicationInsights } from "@microsoft/applicationinsights-web";

const appInsights = new ApplicationInsights({
  config: {
    connectionString:
      "InstrumentationKey=36ab2898-5d6b-4c26-bcb0-4520c6b02499;IngestionEndpoint=https://uksouth-1.in.applicationinsights.azure.com/;LiveEndpoint=https://uksouth.livediagnostics.monitor.azure.com/",
  },
});
appInsights.loadAppInsights();

import { version } from "../../package.json";
console.log("Version: ", version);

appInsights.context.application.ver = version;

export default appInsights;
