import {createStore} from 'vuex';
import {auth} from './auth.module';

const store = createStore({
    state: {
        //stores the data of the app
    },
    mutations: {
        //we commit(trigger) mutation 
        //methods that change the data that are in the state 
        //we can only change the state by triggering the methods in mutation 
        //can only trigger synchronous data 
    },
    actions: {
        //we dispatch actions 
        //they cannot access or change the data in the state 
    },
    modules: {
        //breaks the store into multiple modules with each module having its own state, mutations, actions and getters
        auth,
        // sites
    }
});

export default store;